import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'hb-empty',
  templateUrl: './empty.component.html',
  styles: [
    `
      div.main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* background: url(/assets/icons/bg.jpg); */
        background-repeat: no-repeat;
        background-size: cover;
      }
      div.section {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 3rem;
      }
      div.cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.2;
        z-index: 0;
      }
      h1 {
        margin-top: 5rem;
        font-size: 10rem;
      }
      h2:first-of-type {
        margin-top: 4rem;
      }
      h1,
      h2 {
        color: #da4e4e;
      }
      span {
        margin-left: 1rem;
      }
      img {
        margin-top: 2rem;
        height: 20rem;
      }
      button {
        background-color: #14aa9c;
        color: white;
        margin-bottom: 3rem;
      }
      button:hover {
        background-color: #14abbb;
      }

      @media only screen and (max-width: 600px) {
        img {
          height: 9rem !important;
        }
      }
    `
  ],
  standalone: true,
  imports: [MatButtonModule, RouterLink, MatIconModule]
})
export class EmptyComponent {}
