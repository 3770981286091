<div class="main">
  <div class="section">
    <h1>404</h1>
    <h2 i18n>Oops</h2>
    <h2 i18n>This link is not valid</h2>
    <button mat-raised-button routerLink="/">
      <mat-icon svgIcon="globe"></mat-icon>
      <span class="link-text" i18n>Back to home</span>
    </button>
  </div>
  <img loading="lazy" src="assets/icons/404.png" alt="dead link" i18n-alt />
</div>
